.theme-builder-btn {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border-radius: 5px;
  padding: 5px;
  z-index: 100;
  // background-color: white;

  svg {
    border-radius: 5px;
    background: black;
    path {
      fill: white;
    }
  }
}

$DrawerSize: 550px;
.theme-builder-dlg {
  .modal-dialog {
    position: fixed;
    transform: translate(400px, 0) !important;
    right: 0;
    bottom: 0;
    margin: 0;
    max-width: $DrawerSize;
    .modal-content {
      border-radius: 0;
      min-width: $DrawerSize;
      max-width: $DrawerSize;
      height: 93vh;
      overflow-y: auto;
      overflow-x: hidden;
      $pickerSize: 3rem;
      .modal-header {
        .close {
          color: #fff;
        }
      }
      section {
        padding: 1rem;
        border: 1px solid #eee;
        border-radius: 5px;
        margin-bottom: 1rem;
        h5 {
          margin-bottom: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        hr {
          background: white;
        }
        .radio-group {
          display: flex;
          flex-wrap: wrap;
          .custom-control {
            flex: 0 0 100%;
            margin-bottom: 0.5rem;
          }
        }
        .input-group {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          label {
            min-width: 150px;
            font-weight: 500;
            margin: 0;
          }
        }
      }
      .position-sticky {
        position: sticky;
        top: 0;
        z-index: 10000;
        background-color: white;
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.15);
        width: calc(100% + 3rem);
        margin: 0 auto 2rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
      .ui-color-picker {
        // flex-direction: row;
        // position: relative;
        // padding-top: 4rem;
        width: 100%;
        padding: 1rem;

        .gradient-controls {
          // flex-direction: column;
        }
        .gradient-degree-value p {
          color: #111 !important;
          margin: 0;
        }
        .input-field {
          .input-container {
            input {
              margin: 0 !important;
              color: #111 !important;
            }
          }
        }
        // .gradient {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   height: $pickerSize;
        // }
      }
    }
  }
  &.show {
    .modal-dialog {
      transform: translate(0, 0) !important;
    }
  }
}
