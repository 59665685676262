@import "./variables";

header {
  .navbar {
    // justify-content: space-between;
    width: 100%;
    // display: grid;
    // grid-auto-flow: column;
    // grid-template-columns: 300px;
    .navbar-brand {
      color: $themePrimary !important;
      svg {
        max-width: 200px;
      }
    }
    .btn {
      @media screen and (max-width: 991px) {
        span {
          display: none;
        }
      }
    }
  }
}
