@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "./variables";

body {
  letter-spacing: 0.25px;
  font-family: $font-pri !important;
  font-size: 0.9rem;
}
.container {
  max-width: 1000px !important;
}

.max-w-400 {
  max-width: 400px;
}

.theme-switcher {
  i {
    font-size: 1.5rem;
    color: $light !important;
  }
}

.app-wrapper {
  padding: 2rem 1rem;
}

.mini-underline {
  border: 0;
  margin: 0;
  &:after {
    content: "";
    border-radius: 50px;
    height: 0.35rem;
    width: 5rem;
    position: relative;
    display: inline-block;
  }
}

.medium-underline {
  border: 0;
  margin: 0;
  &:after {
    content: "";
    border-radius: 50px;
    height: 0.35rem;
    width: 10rem;
    position: relative;
    display: inline-block;
  }
}
