
.withdraw-item-container {
    min-height: 80px;
    border-radius: 5px;
    border-style: solid;
    border-color: #ffffff20;
    padding: 8px;
}

.withdraw-item-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;


    .token-logo {
        width: 40px;
    }
}

.withdraw-item-head * {
    margin-right: 8px;
}

.loadMore_comp{
    margin: 1% 1%;
    border-style: solid;
    border-width: 0.1px;
    border-color: #80808033;
    cursor: pointer;

    &.disbaled{
        color: grey
    }

    &.active{
        &:hover{
            background-color: #161515;
        }
    }
   
}