@import "./variables";

.steps-wrapper {
  .step-link {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    border: 0;
    text-decoration: none;
    position: relative;
    .step-circle {
      background: #ddd;
      border-radius: 100%;
      min-width: 40px;
      width: 40px;
      height: 40px;
      float: left;
      margin-right: 1rem;
      border: 2px solid lighten($themePrimary, 20);
      position: relative;
    }
    &:hover {
      small {
        color: $light !important;
      }
    }
    &:last-child {
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      width: 2px;
      height: calc(100% + 2rem);
      position: absolute;
      top: 0;
      left: 1.25rem;
      background-color: lighten($themePrimary, 20);
    }
    &.is-selected {
      .step-circle {
        background: lighten($themePrimary, 10);
        &:before {
          background: lighten($themePrimary, 5);
        }
      }
    }
    &.is-done {
      .step-circle {
        background: lighten(green, 10);
        &:before {
          content: "\f12c";
          font-family: $font-mat;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2rem;
          color: $light;
        }
      }
      &:before {
        background: lighten(green, 10);
      }
    }
  }
}
.ant-steps-icon {
  width: 100%;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.network-swap {
  margin: 1.8rem 0;
  .card {
    .dropdown {
      .dropdown-toggle {
        padding: 4px !important;
        min-width: 30px;
        height: 30px;
        i {
          margin: 0;
          font-size: 1.5rem;
          line-height: 1;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .btn-swap {
    i {
      font-size: 2rem;
    }
  }
}

.connect-amount {
  input {
    background: none;
    font-size: 2rem;
    font-weight: normal;
    line-height: 2;
    border-radius: 10px;
    &:focus {
      background: none;
    }
  }
}

.top-banner {
  text-align: left;
  display: flex;
  align-items: center !important;
  &.btn.btn-pri {
    border-radius: 0 !important;
  }
  .ant-alert-message {
    line-height: 1;
    font-weight: 600;
  }
  .ant-alert-description {
    line-height: 1 !important;
  }
  .anticon-close {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: 1px;
  }
  &.ant-alert-warning {
    padding: 10px 15px 15px 24px !important;
    //   margin: 3px;
    //   margin-right: 10px;
  }
}
