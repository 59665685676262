@import "./variables";

.btn {
  border: none !important;
  box-shadow: none;
  font-weight: 500 !important;
  align-items: center;
  i {
    font-size: 1.5rem;
    line-height: 1;
    margin-right: 0.5rem;
  }
  &:hover {
    color: $dark !important;
    box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.25);
  }
  &:active,
  &:focus {
    box-shadow: none !important;
    border: none;
    outline: none;
  }
  &.btn-theme {
    display: inline-block;
    padding: 0;
    max-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border: 1px solid $themePrimary !important;
    cursor: pointer;
  }
  &.btn-connect {
    white-space: nowrap;
    display: flex;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }

  &.btn-withdrawl {
    white-space: nowrap;
    display: flex;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }

  &.btn-switch-network {
    white-space: nowrap;
    display: flex;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
  &.btn-asset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      display: flex;
      align-items: center;
      img {
        margin-right: 0.5rem;
      }
    }
    &.dropdown-toggle {
      width: 100%;
      i {
        margin: 0;
        font-size: 1.5rem;
      }
      &:after {
        display: none;
      }
    }
  }
}
