@import "./variables";

.card {
  border: 0;
  padding: 1.5rem;
  width: 100%;
  &.card-connect-wallet {
    padding: 2rem 5rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem;
    }
  }

}

.icon-network {
  img {
    width: 100%;
  }
  &.icon-lg {
    max-width: 40px;
  }
  &.icon-sm {
    max-width: 15px;
  }
}
